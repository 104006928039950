import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/header";
import Vision from "./containers/Vision/vision";
import SearchConsole from "./containers/SearchConsole/search-console";
import "./App.scss"
import {validateApplicationToken} from "./containers/SearchConsole/http-request";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./store";
import {loginApplication, notValidatedApplication} from './features/Authentication/userSlice'
import Dashboard from "./containers/Dashboard/dashboard";
import Login from "./containers/Login/login";
import Reports from "./containers/Report/reports";
import IndividualSite from "./containers/SearchConsole/IndividiualSite/individual_site";
import SeoChangeTracking from "./containers/SeoChangeTracking/seo-change-tracking";
import Sifter from "./containers/Sifter/sifter";
import ChatGPT from "./containers/ChatGPT/chat-gpt";
import UserList from "./containers/User/list";
import ChatGPTRewrite from "./containers/ChatGPT/chat-gpt-rewrite";
import OpenAiBulk from "./containers/OpenAI/open-ai-bulk";


function App() {
    const userState = useSelector((state: RootState) => state.user)
    const dispatch = useDispatch()
    const [loggedIn, setLoggedIn] = useState(userState.applicationLoggedIn)
    useEffect(() => {
        if(userState.applicationLoggedIn || userState.applicationToken){
            validateApplicationToken({'token': userState.applicationToken}).then(response => {
                dispatch(loginApplication({
                    applicationToken: userState.applicationToken,
                    permissions: response.data.permissions
                }))
                setLoggedIn(true)
            }).catch(error => {
                if(error.response){
                    dispatch(notValidatedApplication())
                    setLoggedIn(false)
                }
            })
        }
    }, [userState.applicationLoggedIn, userState.applicationToken, dispatch])
    return (
        <div className="App container">
            <Router>
                {!loggedIn ? <Login/> :
                    <div>
                        <Header setLoggedIn={setLoggedIn}/>
                        <div className='content'>
                            <Routes>
                                <Route path='/vision' element={<Vision/>} />
                                <Route path='/' element={<Dashboard/>} />
                                <Route path='/search-console' element={<SearchConsole/>} />
                                <Route path='/seo-change-tracking' element={<SeoChangeTracking/>} />
                                <Route path='/reports' element={<Reports/>} />
                                <Route path='/search-console-report' element={<IndividualSite site_url={''}/>} />
                                <Route path='/sifter' element={<Sifter/>} />
                                <Route path='/chat-gpt' element={<ChatGPT/>} />
                                <Route path='/chat-gpt-rewrite' element={<ChatGPTRewrite/>} />
                                <Route path='/users' element={<UserList/>} />
                                <Route path='/open-ai-bulk' element={<OpenAiBulk/>} />
                            </Routes>
                        </div>
                    </div>
                }
            </Router>
        </div>
    );
}

export default App;
