import React from "react";

const COLOR_MAPPING = {
    PERSON: '#ea4335',
    ORGANIZATION: '#4285f4',
    LOCATION: '#34a853',
    CONSUMER_GOOD: '#a142f4',
    OTHER: '#9aa0a6',
    EVENT: '#f29900',
    ADDRESS: '#f538a0',
    PRICE: '#b31412',
    NUMBER: '#185abc',
    DATE: '#755bda',
    PHONE_NUMBER: '#f321cb',
    WORK_OF_ART: '#aaaa05'
}

const selectedColors = {}
for (const key of Object.keys(COLOR_MAPPING)) {
    selectedColors[COLOR_MAPPING[key]] = true;
}

const generateColor = () => {
    let randomColorString = "#";
    const arrayOfColorFunctions = "0123456789abcdef";
    for (let x = 0; x < 6; x++) {
        let index = Math.floor(Math.random() * 16);
        let value = arrayOfColorFunctions[index];

        randomColorString += value;
    }
    return randomColorString;
};

export const bgColor = id => {
    // If already generated and assigned, return
    if (COLOR_MAPPING[id]) return COLOR_MAPPING[id];

    // Generate new random color
    let newColor;

    do {
        newColor = generateColor();
    } while(selectedColors[newColor]);

    // Found a new random, unassigned color
    COLOR_MAPPING[id] = newColor;
    selectedColors[newColor] = true;

    // Return next new color
    return newColor;
}

export const nFormatter = (num) => {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num;
}

export const getDateTimeWithoutTimezone = (dateString) => {
    return new Date(`${dateString} UTC`).toString().slice(0, 24)
}

export const getFormattedDate = (dateString) => {
    return new Date(`${dateString} UTC`).toDateString()
}

export const getTimeWithoutTimezone = (dateString) => {
    return new Date(`${dateString} UTC`).toTimeString().slice(0, 9)
}

export const createLink = (text, href) => {
    return <a  rel="noreferrer" target='_blank' href={href}>{text}</a>
}

export const getTagAndLocation = (element) => {
    return element.split("-#SEP#-")
}

export const getTableHeader = (activity_id, created_at) => {
    return (
        <div className={'card-header'}>
            <h3>Activity #{activity_id}</h3>
            <h4>{getTimeWithoutTimezone(created_at)}</h4>
        </div>
    )
}


export const exportFile = (props: {content: string | Blob, fileName: string, contentType: string}) => {
    const {content, fileName, contentType} = props
    let blob: Blob;
    if(contentType === 'text') {
        blob = new Blob([content], { type: 'text/plain' });
    } else {
        blob = content as Blob
    }
    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);
    // Create a hidden anchor element
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    // Append the anchor element to the body
    document.body.appendChild(a);
    // Trigger a click event on the anchor element
    a.click();
    // Remove the anchor element from the body
    document.body.removeChild(a);
    // Revoke the temporary URL
    URL.revokeObjectURL(url)
}
