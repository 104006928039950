import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faInfo} from "@fortawesome/free-solid-svg-icons";
import {Formik} from "formik";
import {useMutation} from "react-query";
import Loader from "../../components/Loader/loader";
import {createOpenAIBulkRequest} from "../../http-request";
import {OpenAISingleResponse} from "../../interface/openai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const OpenAIBulk = () => {
    const [fetching, setFetching] = useState(false)
    const [openAIResponse, setOpenAIResponse] = useState<OpenAISingleResponse>()
    const createOpenAIBulkRequestMutation = useMutation(createOpenAIBulkRequest);

    return (
        <div className={'open-ai'}>
            <div className={'open-ai-form'}>
                <Formik
                    initialValues={{
                        prompt: '',
                        report_name: '',
                        input_file: ''
                    }}
                    validate={values => {
                        const errors: { [index: string]: any } = {}
                        if (!values.prompt) {
                            errors.prompt = 'Please enter the prompt';
                        }
                        if (!values.report_name) {
                            errors.report_name = 'Please enter the report name';
                        }
                        if (!values.input_file) {
                            errors.site_url = 'Please upload file a zip file with txt files inside it.';
                        }
                        return errors;
                    }}
                    onSubmit={(values) => {
                        const submit = async () => {
                            setFetching(true)
                            createOpenAIBulkRequestMutation.mutateAsync(
                                {
                                    prompt: values.prompt,
                                    report_name: values.report_name,
                                    input_file: values.input_file
                                },
                                {
                                    onSuccess: (res) => {
                                        setFetching(false)
                                        setOpenAIResponse(res)
                                    },
                                    onError: (res) => {
                                        setFetching(false)
                                    }
                                })
                        }
                        submit()
                    }}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} className="border p-4">
                            <div className={"row"}>
                                <h3 className={'text-center'}>Chat GPT</h3>
                                <hr></hr>
                            </div>
                            <div className="row mt-4" style={{alignItems: 'center'}}>
                                <div className="col-md-6">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="report_name">Report Name</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="report_name-tooltip">
                                                        Provide a unique name for the report.
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="report_name"
                                        name="report_name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.report_name}
                                    />
                                    <span
                                        className='input-error'>{errors.report_name && touched.report_name && errors.report_name}</span>
                                </div>
                                <div className="col-md-6">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="input_file">File</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="input_file-tooltip">
                                                        Please upload a ZIP file containing one or more TXT files. Ensure that all files within the ZIP archive are in plain text format (.txt)
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="input_file"
                                        name="input_file"
                                        accept=".zip"
                                        onChange={(event) => {
                                            const files = event.currentTarget.files;
                                            if (files !== null) {
                                                setFieldValue("input_file", files[0]);
                                            }
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <span
                                        className='input-error'>{errors.input_file && touched.input_file && errors.input_file}</span>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className={'row'}>
                                        <div className={'col-md-6'}>
                                            <label htmlFor="prompt">Input Prompt</label>
                                        </div>
                                        <div className={'col-md-6'}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip id="prompt-tooltip">
                                                        Instructions provided to the AI for generating content.
                                                    </Tooltip>
                                                }
                                            >
                                                <div className={'fa-pull-right m-1'}>
                                                    <FontAwesomeIcon icon={faInfo}/>
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <textarea
                                        rows={8}
                                        className="form-control"
                                        id="prompt"
                                        name="prompt"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.prompt}
                                    />
                                    <span
                                        className='input-error'>{errors.prompt && touched.prompt && errors.prompt}</span>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                <button
                                    disabled={createOpenAIBulkRequestMutation.isLoading}
                                    className="btn m-1"
                                    style={{backgroundColor: 'coral'}}
                                    type="submit"
                                >
                                    <FontAwesomeIcon icon={faGear}/> Process
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            {fetching ? (
                <div className="fetch-details">
                    <Loader/>
                </div>
            ) : (
                <div>
                    <div className="result">
                        {openAIResponse !== undefined &&
                            <div style={{width: '100%'}}>
                                <div className="container mt-4 p-3">
                                    <div className={"text-center"}>
                                        <div className="card mb-3">
                                            <div className="card-body">
                                                {openAIResponse.success ? (
                                                    <div className="alert alert-success" role="alert">
                                                        Task has been queued successfully. Please check the Reports page
                                                        for status updates.
                                                    </div>
                                                ) : (
                                                    <div className="alert alert-danger" role="alert">
                                                        Error: {openAIResponse.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            )
            }
        </div>
    )
}

export default OpenAIBulk
